import * as React from "react"
import fetchAPI from '~/utils/ssr.js';
import {
  Hero, ImageWithCopy, P2Carousel,
} from "~/legacy-components";
import {useLoaderData} from '@remix-run/react';
import SectionHeader from '~/components/SectionHeader/SectionHeader.jsx';
import ModuleList from '~/components/ModuleList/ModuleList.jsx';

export const meta = (props) => {
  const {title, globalSeo, cmsUrl} = props.data;

  if(!globalSeo) {
    return [
      { title: title },
    ]
  }
  const description = 'A NikeSB x Neurosequential Model Project'
  const image = `/assets/uploads/Cover-Flat-Trimmed.jpg`

  return [
    { title: title },
    { name: "description", content: description },
    { name: "url", content: "https://ybos.nikesb.com" },
    { property: "og:image", content: `${cmsUrl}${image}` },
    { property: "og:title", content: title },
    { property: "og:description", content: description },
    { property: "og:type", content: "website" },
    { property: "og:url", content: "https://ybos.nikesb.com" },
    { name: "twitter:title", content: title },
    { name: "twitter:description", content: description },
    { name: "twitter:image", content: `${cmsUrl}${image}` },
    { name: "twitter:card", content: "summary_large_image" },
  ];
};

export const loader = async () => {

  const data = await fetchAPI('home.json');
  return {
    ...data.props,
    cmsUrl: process.env.CMS_URL,
  } ;
}


const IndexPage = () => {
  const data = useLoaderData(); // this gets you the data from the loader function
  const {
    hero,
    featuredComics = [],
    modules = [],
    showAllComics,
    allComics,
  } = data;

  let randomLink = '/';
  if (allComics && allComics.length > 0) {
    const randomComic = allComics[Math.floor(Math.random() * allComics.length)];
    randomLink = `/${randomComic.slug}`;
  }


  return (
    <main>
      <Hero {...hero} />
      {featuredComics.map((comic, index) => {
        return (
          <div key={`feature-${index}`}>
            <SectionHeader eyebrow={comic.sport} headline={comic.comicTitle} />
            <ImageWithCopy borderColor={comic.backgroundColor} copy={`<p>${comic.description}</p>`} image={comic.titlePanel} slug={comic.slug}/>
          </div>
        )
      })};
      <ModuleList modules={modules} />
      {showAllComics && <P2Carousel comics={allComics} headline={'MORE FROM YOUR BRAIN ON SPORT'} randomLink={randomLink} />}
    </main>
  )
}
export default IndexPage
